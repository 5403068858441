import originJsonp from 'jsonp'
import axios from 'axios'
import qs from 'qs'
import { ElMessage as message } from 'element-plus'
import { getCookie, delCookie, handleLogout } from './utils'
import $http from '@/pages/api/index'
// axios.defaults.withCredentials = true

axios.defaults.headers = {
  'Content-Type': 'application/x-www-form-urlencoded'
}

let headerToken = 'X-Access-Token'
//POST传参序列化
axios.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        config.data = qs.stringify(config.data)
      }
    }
    if (config.custom) {
      config.headers = {
        'Content-Type': config.custom.headers['Content-Type']
      }
    }
    if (config.headers['Content-Type'] === 'application/json') {
      config.data = qs.parse(config.data)
    }

    if (getCookie(headerToken)) {
      if (config.url.indexOf('fteaching') > -1 || config.url.indexOf('hike-shared-library') > -1 || config.url.indexOf('oauth.zhihuishu.com/logout') > -1) {
      } else {
        config.headers[headerToken] = getCookie(headerToken)
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

//返回状态判断
axios.interceptors.response.use(
  res => {
    if (res.data.code == 10002) {
      message.error('登录过期，请重新登录')
      handleLogout()
      return
    }
    if ((res.data.status && res.data.status == 200) || res.data.code == 200) {
      return Promise.resolve(res)
    } else {
      return Promise.reject(res)
    }
  },
  error => {
    return Promise.reject(error)
  }
)
export function getFetch(url, params = {}) {
  return axios.get(url, { params }).then(
    res => {
      // console.log("+============================",{res});
      if ((res.data.status && res.data.status == 200) || res.data.code == 200) {
        return Promise.resolve(res.data.rt || res.data.data)
      }
      return Promise.reject(res.data)
    },
    error => {
      // console.log("+-----------------------",error);
      message.error(error.message || error.data.msg || error.data.message)
      // message.error('网络错误，请稍后重试');
      return Promise.reject(error)
    }
  )
}
export function postFetch(...args) {
  return axios.post(...args).then(
    res => {
      if ((res.data.status && res.data.status == 200) || res.data.code == 200) {
        return Promise.resolve(res.data.rt || res.data.data)
      }
      return Promise.reject(res.data)
    },
    error => {
      message.error(error.message || error.data.message || error.data.msg)
      // message.error('网络错误，请稍后重试');
      return Promise.reject(error)
    }
  )
}
export function jsonp(url, params = {}, option) {
  return new Promise((resolve, reject) => {
    let finalUrl = url + (url.indexOf('?') < 0 ? '?' : '&') + qs.stringify(params)
    originJsonp(finalUrl, { param: 'callback', ...option }, (err, data) => {
      if (!err) {
        if (data.currentTime) {
          window.currentTime = data.currentTime
        }
        if (data && data.status === '200') {
          resolve(data.rt) //请求成功
        } else {
          reject(data.msg)
        }
      } else {
        reject(err) //失败
      }
    })
  })
}
