/**
 * 类型字典
 * */

// 文本
export const TEXTTYPES = ['dox', 'docx', 'xls', 'xlsx', 'pdf']

// 演示文稿
export const PAGETYPES = ['dox', 'docx', 'xls', 'xlsx', 'pdf']

// 音频
export const AUDIOTYPES = ['mp3', 'wma', 'rm', 'wav', 'midi', 'ape', 'flac']

// 视频
export const VIDEOTYPES = ['asf', 'avi', 'flv', 'm4v', 'mov', 'mp4', 'rmvb', 'wmv', 'mkv', 'mpeg', 'rm', 'mpg', 'ogg', 'webm']

// 图片
export const PICTYPES = ['gif', 'jpg', 'jpeg', 'png', 'bmp']
