import baseApp from '../plugins/baseApp'
// import nuc from './api/nuc';
import api from './api'
import caseApi from './api/case'
import teachCenterApi from './api/teachCenter'
import courseDetailApi from './api/courseDetail'
import teachingCenterList from './api/teachingCenterList'
import microSpecialty from './api/microSpecialty'
import lectureApi from './api/lecture'
import teachingMaterialApi from './api/teachingMaterial'
import setup from '@/plugins/permission'
// import 'element-plus/packages/theme-chalk/src/base.scss'

export default async function (App) {
  const schoolId = await setup()
  let vm = baseApp(App, schoolId)
  vm.config.globalProperties.$http = {
    ...vm.config.globalProperties.$http,
    ...api,
    ...caseApi,
    ...teachCenterApi,
    ...courseDetailApi,
    ...teachingCenterList,
    ...microSpecialty,
    ...lectureApi,
    ...teachingMaterialApi,
  }
  // vm.config.globalProperties.$http = { ...vm.config.globalProperties.$http, ...api, nuc }
  vm.config.globalProperties.$schoolUrl = `https://${process.env.VUE_APP_HOST}`
  vm.provide('$schoolId', schoolId).provide('$http', { ...vm.config.globalProperties.$http })

  vm.config.errorHandler = (err, vm, info) => {
    // 处理错误
    // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
    console.log({ err, vm, info })
    console.log(err.stack)
  }
  return vm
}
