import axios from "axios";
import plugins from "./plugins";
import toolbar from "./toolbar";
export default {
  name: "Tinymce",
  emits: ["update:modelValue"],
  props: {
    id: {
      type: String,
      default: function () {
        return "vue-tinymce-" + +new Date() + ((Math.random() * 1000).toFixed(0) + "");
      }
    },
    modelValue: {
      type: String,
      default: ""
    },
    toolbar: {
      type: Array,
      required: false,

      default() {
        return [];
      }

    },
    menubar: {
      type: String,
      default: "file edit insert view format table"
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360
    },
    width: {
      type: [Number, String],
      required: false,
      default: "auto"
    }
  },

  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false
    };
  },

  computed: {
    containerWidth() {
      const width = this.width;

      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        return `${width}px`;
      }

      return width;
    }

  },
  watch: {
    modelValue(val) {
      if (!this.hasChange) {
        this.$nextTick(() => window.tinymce.get(this.tinymceId).setContent(val || ""));
      }
    }

  },

  mounted() {
    this.init();
  },

  activated() {
    if (window.tinymce) {
      this.initTinymce();
    }
  },

  deactivated() {
    this.destroyTinymce();
  },

  unmounted() {
    this.destroyTinymce();
  },

  methods: {
    init() {
      this.initTinymce();
    },

    initTinymce() {
      const _this = this;

      window.tinymce.init({
        language: "zh_CN",
        selector: `#${this.tinymceId}`,
        height: this.height,
        body_class: "panel-body ",
        object_resizing: true,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: false,
        plugins: plugins,
        contextmenu: "",
        contextmenu_never_use_native: false,
        end_container_on_empty_block: true,
        powerpaste_word_import: "clean",
        paste_data_images: true,
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: "square",
        advlist_number_styles: "default",
        default_link_target: "_blank",
        link_title: false,
        nonbreaking_force_tab: true,
        lineheight_formats: "1 1.5",
        paste_enable_default_filters: true,
        paste_retain_style_properties: "color font-size text-indent text-align border-collapse",
        paste_word_valid_elements: "table[width|border],tr,td[colspan|rowspan|width],th[colspan|rowspan|width],thead,tfoot,tbody,h1,h2,h3,h4,h5,img,p,span",
        init_instance_callback: editor => {
          // debugger
          if (_this.modelValue) {
            editor.setContent(_this.modelValue);
          }

          _this.hasInit = true;
          editor.on("NodeChange Change KeyUp SetContent", () => {
            this.hasChange = true;
            this.$emit("update:modelValue", editor.getContent());
          });
        },

        setup(editor) {
          editor.on("FullscreenStateChanged", e => {
            _this.fullscreen = e.state;
          });
        },

        images_upload_handler: function (blobInfo, success, failure) {
          var filename = blobInfo.filename();

          _this.$http.getPolicy({
            fileName: filename
          }).then(res => {
            var data = JSON.parse(res);
            var formData = new FormData();
            formData.append("key", data.dir + data.fileUUID + "." + filename.split(".")[filename.split(".").length - 1]);
            formData.append("policy", data.policy);
            formData.append("OSSAccessKeyId", data.accessid);
            formData.append("success_action_status", 200);
            formData.append("callback", data.callback);
            formData.append("signature", data.signature);
            formData.append("Content-Disposition", "attachment;filename=" + filename);
            formData.append("file", blobInfo.blob());
            axios({
              method: "POST",
              url: data.host,
              data: formData,
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(result => {
              console.log(success);
              success(result.data.data.fileUrl);
            }).catch(() => {
              failure("上传失败");
            });
          });
        }
      });
    },

    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId);

      if (this.fullscreen) {
        tinymce.execCommand("mceFullScreen");
      }

      if (tinymce) {
        tinymce.destroy();
      }
    },

    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value);
    },

    getContent() {
      return window.tinymce.get(this.tinymceId).getContent();
    },

    imageSuccessCBK(arr) {
      arr.forEach(v => window.tinymce.get(this.tinymceId).insertContent(`<img class="wscnph" src="${v.url}" >`));
    }

  }
};