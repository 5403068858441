import { ElMessageBox, ElMessageBoxOptions } from 'element-plus'

interface ITitle {
  headerTitle: string
  tipTitle: string
  tipText: string
}

type IConfirm = ITitle & ElMessageBoxOptions

export default (data: IConfirm) => {

  const { tipTitle, tipText, headerTitle, boxType = 'confirm', ...other } = data

  const option: ElMessageBoxOptions = Object.assign(
    {
      confirmButtonText: '确 认',
      cancelButtonText: '取 消',
      dangerouslyUseHTMLString: true,
      type: 'warning'
    },
    other
  )
  if (boxType === 'confirm') {
    return ElMessageBox.confirm(
      `<div class="tip-title">${tipTitle}</div>
        <span class="tip-text">${tipText}</span>`,
      headerTitle,
      option
    )
  } else if (boxType === 'alert') {
    return ElMessageBox.alert(
      `<div class="tip-title">${tipTitle}</div>
      <span class="tip-text">${tipText}</span>`,
      headerTitle,
      option
    )
  }
}
