/**
 * 封装app
 * @param {Component} app 需要挂载的组件
 * @param {schoolId} schoolId schoolId
 */

import { createApp } from 'vue'
import { jsonp, getFetch, postFetch } from './http'
import * as utils from './utils'
import 'assets/css/markdown.min.css'
import './../assets/index.scss'
import './../assets/font/iconfont.css'
import('assets/font/iconfont')
import '../assets/element.scss'
import * as Icons from '@element-plus/icons-vue'
import installIcons from '@/pages/components/SvgIcon'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import infiniteScroll from 'vue-infinite-scroll'

let relativeTime = require('dayjs/plugin/relativeTime')
dayjs.locale('zh-cn')
dayjs.extend(relativeTime)
let baseUrl = ''
// const objectStatus = ref(true)
export default function (app, schoolId = 0) {
  const http = {}
  const vm = createApp(app)
  vm.use(infiniteScroll)

  const host = window.location.protocol + '//' + window.location.host
  const url = host + '/index.html'
  const zhsLoginUrl = `${host}/hike-boot/AuthSys/getAuthorize?redirectUri=${url}&failRedirectUri=${url}&source=${schoolId}`

  /* 统一注册图标库 */
  Object.keys(Icons).forEach(key => {
    vm.component(key, Icons[key])
  })
  installIcons(vm)

  vm.config.globalProperties.$jsonp = jsonp
  vm.config.globalProperties.$schoolId = schoolId
  vm.config.globalProperties.$http = http
  vm.config.globalProperties.$utils = utils
  vm.config.globalProperties.$get = getFetch
  vm.config.globalProperties.$post = postFetch
  vm.config.globalProperties.$baseUrl = baseUrl
  vm.config.globalProperties.$dayjs = dayjs
  vm.config.globalProperties.$zhsLoginUrl = zhsLoginUrl
  vm.provide('$utils', utils).provide('$fileHost', '').provide('$zhsLoginUrl', zhsLoginUrl).provide('$baseUrl', baseUrl)
  return vm
}
