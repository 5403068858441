import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-a902e71a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "footer"
};
const _hoisted_2 = {
  class: "foot"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $data.footer ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($data.footer), 1)])) : _createCommentVNode("", true);
}