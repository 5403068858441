// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/add_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".el-button.is-plain[data-v-72a1884f]{--el-button-active-text-color:var(--el-color-primary);--el-button-active-border-color:var(--el-color-primary)}.el-button--primary.is-plain[data-v-72a1884f]{--el-button-border-color:var(--el-color-primary-light-3)}.el-link.el-link--primary[data-v-72a1884f]:hover{color:var(--el-color-primary-light-3)}.search-box[data-v-72a1884f]{display:flex;align-items:center}.search-box .input-box[data-v-72a1884f]{width:350px;margin-right:23px}.search-box .input-box[data-v-72a1884f] .el-input__suffix{padding-top:14px;cursor:pointer}.table-box[data-v-72a1884f]{margin-top:13px}.table-box .table-btn[data-v-72a1884f]{padding:0}.footer-btn[data-v-72a1884f]{flex:1;display:flex;align-items:center;justify-content:space-between}.footer-btn .add-btn[data-v-72a1884f]{color:#222;font-size:14px;cursor:pointer;display:flex;align-items:center}.footer-btn .add-btn .add-icon[data-v-72a1884f]{display:inline-block;width:16px;height:16px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;margin-right:10px}", ""]);
// Exports
module.exports = exports;
