/**
 * @description: 颜色阶梯过渡（均匀渐变）Gradient算法
 * @param {*} start 开始的颜色
 * @param {*} end 结束的颜色
 * @param {*} steps 阶梯过渡的层次
 * @param {*} gamma 透明度
 * @return {*}
 */
function gradientColors(start, end = '#ffffff', steps = 11, gamma = 1) {
  function pad(s) {
    return s.length === 1 ? '0' + s : s
  }

  function parseColor(hexStr) {
    return hexStr.length === 4
      ? hexStr
          .substr(1)
          .split('')
          .map(function (s) {
            return 0x11 * parseInt(s, 16)
          })
      : [hexStr.substr(1, 2), hexStr.substr(3, 2), hexStr.substr(5, 2)].map(function (s) {
          return parseInt(s, 16)
        })
  }

  function normalize(channel) {
    return Math.pow(channel / 255, gamma)
  }

  let i,
    j,
    ms,
    me,
    output = [],
    so = []

  start = parseColor(start).map(normalize)
  end = parseColor(end).map(normalize)

  for (i = 0; i < steps; i++) {
    ms = i / (steps - 1)
    me = 1 - ms
    for (j = 0; j < 3; j++) {
      so[j] = pad(Math.round(Math.pow(start[j] * me + end[j] * ms, 1 / gamma) * 255).toString(16))
    }
    output.push('#' + so.join(''))
  }
  return output
}

// 获取 rgb 颜色值
function getRgbNum(sColor) {
  if (sColor.length === 4) {
    let sColorNew = '#'
    for (let i = 1; i < 4; i += 1) {
      // 补全颜色值 例如：#eee,#fff等
      sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
    }
    sColor = sColorNew
  }
  // 处理六位颜色值
  let sColorChange = []
  for (let i = 1; i < 7; i += 2) {
    // 核心代码，通过parseInt将十六进制转为十进制，parseInt只有一个参数时是默认转为十进制的，第二个参数则是指定转为对应进制
    sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
  }
  return sColorChange
}

// str: 十六进制颜色值，n：透明度
export function colorRgba(str, n) {
  // 十六进制颜色值的正则表达式
  let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  let sColor = str.toLowerCase()
  n = n || 1
  // 十六进制颜色转换为RGB格式
  if (sColor && reg.test(sColor)) {
    let sColorChange = getRgbNum(sColor)
    return 'rgba(' + sColorChange.join(',') + ',' + n + ')'
  } else {
    return sColor
  }
}

// str: 十六进制颜色值
export function colorRgbParame(str) {
  // 十六进制颜色值的正则表达式
  let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  let sColor = str.toLowerCase()
  // 十六进制颜色转换为RGB格式
  if (sColor && reg.test(sColor)) {
    let sColorChange = getRgbNum(sColor)
    return sColorChange.join(',')
  } else {
    return '0, 0, 0'
  }
}

export function setThemeColor(primaryColor = '#3c7ae1', id) {
  var gradient = gradientColors(primaryColor)

  // document.documentElement 是全局变量时
  const el = id ? document.getElementById(id) : document.documentElement

  // 设置 css 变量
  el.style.setProperty('--el-color-primary', primaryColor)
  el.style.setProperty('--el-color-primary-rgb', colorRgbParame(primaryColor))
  for (let i = 1; i <= 9; i++) {
    const val = gradient[i]
    const lebal = `--el-color-primary-light-${i}`
    // 逐个设置 css 变量到 body 上
    el.style.setProperty(lebal, val)
  }
}
