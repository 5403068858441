const config = {
  type: 'localStorage',
  expire: 0
}

// 设置 setStorage
export const setStorage = (key, value, expire = 0) => {
  if (value === '' || value === null || value === undefined) {
    value = null
  }

  if (isNaN(expire) || expire < 0) throw new Error('Expire must be a number')

  expire = (expire ? expire : config.expire) * 60000

  let data = {
    value: value, // 存储值
    time: Date.now(), //存值时间戳
    expire: expire // 过期时间
  }

  window[config.type].setItem(key, JSON.stringify(data))
}

// 获取 getStorage
export const getStorage = key => {
  // key 不存在判断
  if (
    !window[config.type].getItem(key) ||
    JSON.stringify(window[config.type].getItem(key)) === 'null'
  ) {
    return null
  }

  // 优化 持续使用中续期
  const storage = JSON.parse(window[config.type].getItem(key))
  console.log(storage)
  let nowTime = Date.now()
  console.log(config.expire * 6000, nowTime - storage.time)
  // 过期删除
  if (storage.expire && config.expire * 6000 < nowTime - storage.time) {
    removeStorage(key)
    return null
  } else {
    // 未过期期间被调用 则自动续期 进行保活
    setStorage(key, storage.value)
    return storage.value
  }
}

// 获取全部 getAllStorage
export const getAllStorage = () => {
  let len = window[config.type].length // 获取长度
  let arr = new Array() // 定义数据集
  for (let i = 0; i < len; i++) {
    // 获取key 索引从0开始
    let getKey = window[config.type].key(i)
    // 获取key对应的值
    let getVal = window[config.type].getItem(getKey)
    // 放进数组
    arr[i] = { key: getKey, val: getVal }
  }
  return arr
}

// 删除 removeStorage
export const removeStorage = key => {
  window[config.type].removeItem(key)
}

// 清空 clearStorage
export const clearStorage = () => {
  window[config.type].clear();
}
