import { getFetch, postFetch } from '@/plugins/http'

const baseHost = `${process.env.VUE_APP_HOST}/hike-boot`;

export default {
  // /* 全部教案 */
  // getAllCase: (params, config) =>
  //   getFetch(`${baseHost}/common/api/34-all_case_list/lesson_plan_center_portal`, params, config),
  /* 赛选条件列表 */
  excellentCaseFilter: (params, config) =>
    getFetch(
      `${baseHost}/common/api/32-excellent_case_filter/lesson_plan_center_portal`,
      params,
      config
    ),
  /* 后台高级配置 */
  excellentCaseSettingGet: (params, config) =>
    getFetch(
      `${baseHost}/common/api/30-excellent_case_setting_get/lesson_plan_center_portal`,
      params,
      config
    ),
  /* 教案列表 */
  getExcellentCaseList: (params, config) =>
    getFetch(
      `${baseHost}/common/api/34-excellent_case_list/lesson_plan_center_portal`,
      params,
      config
    )
  // /* 更新封面 */
  // updCourseCover: data =>
  //   postFetch(`${baseHost}/common/api/20-upd_course_cover/bk_my_course`, data),
}
