import { getFetch, postFetch } from '@/plugins/http'

const baseHost = `${process.env.VUE_APP_HOST}`
const commonApiBaseUrl = `${baseHost}/hike-boot/common/api`

export default {
  /**
   * 微专业-类型列表
   */
  getClassifyList(params, config) {
    return getFetch(
      `${commonApiBaseUrl}/33-querySpecialtyTypeList/micro_specialty-portal`,
      params,
      config
    )
  },
  /**
   * 微专业-列表
   */
  getMicroList(params, config) {
    return getFetch(
      `${commonApiBaseUrl}/34-querySpecialtyTermPageList/micro_specialty-portal`,
      params,
      config
    )
  },
  /**
   * 微专业_详情_基本信息_根据id查询
   */
  getMicroDetailById(params, config) {
    return getFetch(
      `${commonApiBaseUrl}/30-getSpecialtyInfoById/micro_specialty-portal`,
      params,
      config
    )
  },
  /**
   * 门户_微专业_详情_名师简介_名师列表
   */
  getMicroTeacherList(params, config) {
    return getFetch(
      `${commonApiBaseUrl}/34-getTeacherPageList/micro_specialty-portal`,
      params,
      config
    )
  },
  /**
   * 门户_微专业_学期_详情_运行管理_课程列表
   */
  getMicroCourseList(params, config) {
    return getFetch(
      `${commonApiBaseUrl}/34-getOperationCoursePageList/micro_specialty-portal`,
      params,
      config
    )
  },
  /**
   * 门户_微专业_新增报名
   */
  saveSpecialtyApply(data, config) {
    return postFetch(
      `${commonApiBaseUrl}/10-saveSpecialtyApply/micro_specialty-portal`,
      data,
      config
    )
  },
   /**
   * 门户-微专业-模块设置-列表
   */
   getMicroModuleList(params, config) {
    return getFetch(
      `${commonApiBaseUrl}/33-column_list/micro_specialty-portal`,
      params,
      config
    )
  },
  /**
   * 门户-微专业-子模块信息-列表
   */
  getMicroModuleDetail(params, config) {
    return getFetch(
      `${commonApiBaseUrl}/33-batch_column_info/micro_specialty-portal`,
      params,
      config
    )
  },
}
