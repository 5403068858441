import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      fullscreen: $data.fullscreen
    }, "tinymce-container"]),
    style: _normalizeStyle({
      width: $options.containerWidth
    })
  }, [_createElementVNode("textarea", {
    id: $data.tinymceId,
    class: "tinymce-textarea"
  }, null, 8, _hoisted_1)], 6);
}