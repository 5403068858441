import { unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-384426df"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["xlink:href"];
import { computed } from 'vue';
export default {
  props: {
    // icon 图标
    icon: {
      type: String,
      required: true
    },
    // 图标类名
    className: {
      type: String,
      default: ''
    }
  },

  setup(__props) {
    const props = __props;
    /**
     * 判断当前图标是否为外部图标
     */

    function external(path) {
      return /^(https?:|mailto:|tel:)/.test(path);
    }

    const isExternal = computed(() => external(props.icon));
    /**
     * 外部图标样式
     */

    const styleExternalIcon = computed(() => ({
      mask: `url(${props.icon}) no-repeat 50% 50%`,
      '-webkit-mask': `url(${props.icon}) no-repeat 50% 50%`
    }));
    /**
     * 内部图标
     */

    const iconName = computed(() => `#icon-${props.icon}`);
    return (_ctx, _cache) => {
      return _unref(isExternal) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle(_unref(styleExternalIcon)),
        class: _normalizeClass(["svg-external-icon svg-icon", __props.className])
      }, null, 6)) : (_openBlock(), _createElementBlock("svg", {
        key: 1,
        class: _normalizeClass(["svg-icon", __props.className]),
        "aria-hidden": "true"
      }, [_createElementVNode("use", {
        "xlink:href": _unref(iconName)
      }, null, 8, _hoisted_1)], 2));
    };
  }

};