import { setPagesTitle, setPagesIcon } from './set-pages'
import { setThemeColor } from './change-theme-color'
import { setStorage } from './store'
import api from '@/pages/api'
/**
 * @description:  当前函数的作用仅适用于抽离逻辑  处理页面加载前的各种配置
 * @return {*}
 */
export default async () => {
  try {
    const { getBaseSet } = api
    const res = await getBaseSet()
    /* 将学院配置储存 */
    setStorage('projectBaseData', res)
    /* 动态设置标题 */
    setPagesTitle(res.school_name)
    /* 动态设置浏览器icon */
    setPagesIcon(res.icon_url)
    /* 动态设置主题色 */
    setThemeColor(res.home_primary_color)
    return Promise.resolve(res.school_id)
  } catch (error) {
    console.warn('🤪 error >>:', error)
  }
}