import './../css/head.css';
import { getStorage } from '@/plugins/store';
import { handleLogin, handleLogout } from '@/plugins/utils';
export default {
  props: {
    menuInfo: {
      type: Object
    },
    page: {
      type: String,
      default: '首页'
    },
    searchText: String,
    menuCode: String || Number
  },

  created() {
    this.getObjectStatus();
    this.getUserInfo();
  },

  mounted() {
    this.getBaseSet();
    this.addPv();
    window.addEventListener('scroll', this.handleScroll, true);
  },

  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  data() {
    return {
      isFocus: false,
      // 是否获得焦点
      keyword: this.searchText,
      headInfo: {},
      userInfo: null,
      moocMenu: {},
      spocMenu: {},
      teacherMenu: {},
      newMenu: {},
      logoUrl: '',
      navList: [],
      headerBg: '',
      footer: '',
      url: location.href.substring(location.href.indexOf('?') + 1),
      centerList: [],
      headList: [],
      onlineTeache: null,
      currentPage: '首页',
      userRole: 0,
      zhsRole: 0,
      linkNav: [],
      // 导航中的外部链接
      // teacherMenuId: '',
      showLogin: false,
      backUrl: `${window.location.origin}/admin/`
    };
  },

  computed: {
    CurrentUrl() {
      return this.page || window.location.href;
    }

  },
  methods: {
    handleLogin,
    handleLogout,

    // 关键字搜索
    handleSearch() {
      if (this.keyword) {
        window.location.href = `/search.html?keyword=${this.keyword}`;
      }
    },

    zhsLogin() {
      const host = window.location.protocol + '//' + window.location.host;
      const url = host + '/index.html';
      window.location.href = `${host}/hike-boot/AuthSys/getAuthorize?redirectUri=${url}&failRedirectUri=${url}&source=${this.$schoolId}`;
    },

    // 获取用户信息
    async getUserInfo() {
      let token = this.$utils.getCookie('X-Access-Token'); // token登陆

      if (token) {
        const userInfo = await this.$http.getUserInfo();
        this.userInfo = userInfo;
        console.log(userInfo);
      }

      if (this.userInfo) {
        // this.userInfo.uuid = "VJ77z84b";
        if (this.userInfo.uuid) {
          this.getUserZhsRole();
          this.getUserIdentity();
        }

        this.$emit('getUserInfo', this.userInfo);
      }

      this.getNavList();
    },

    // 获取用户智慧树角色
    async getUserIdentity() {
      let params = {
        schoolId: this.$schoolId,
        uuid: this.userInfo.uuid
      };
      const res = await this.$http.getUserIdentity(params); // 	0:未认证 1:管理者 2:老师 3:学生

      console.log('智慧树角色', res);
      this.userRole = res;
      this.$emit('userRole', this.userRole);
    },

    // 获取用户智慧树权限
    async getUserZhsRole() {
      const res = await this.$http.getUserZhsIdentity({
        uuid: this.userInfo.uuid
      }); //  role = 2 有数据中心权限，role=0 虽然有myuni权限，但是没有数据中心权限 role = 1 数据中心权限、教学指挥中心权限,

      console.log('智慧树权限', res);
      if (res) this.zhsRole = res.role;
    },

    async getNavList() {
      const navlist = await this.$http.getNavList(); // 导航

      const functionList = await this.$http.getObjectOpen(); // 项目已开启功能
      // 菜单显示权限处理

      sessionStorage.setItem('authInfo', JSON.stringify(navlist)); // 详情页判断登录用

      this.centerList = functionList.filter(item => item.target_type == '1'); // 是否有登录按钮

      this.showLogin = functionList.some(item => item.code == '10000'); // 账号设置页用到
      // this.teacherMenuId = navlist.map(item => (item.function_id == '10501' ? item.id : ''))
      // 外部链接列表

      this.linkNav = navlist.filter(item => item.target_page == 1); // 路由集合

      const urlList = {
        10301: '/index.html?',
        10401: '/courseList.html?type=1&',
        10402: '/courseList.html?type=2&',
        10501: '/teacherList.html?',
        10601: '/live.html?',
        10701: '/notice.html?',
        51001: '/teachingCenterList.html?',
        13100: '/microSpecialtyList.html?',
        40005: '/lectureList.html?',
        11900: '/materialList.html?'
      }; // 点击导航跳转到对应taget_url,并传参
      // 重新组装数据

      const data = navlist.map(item => ({ ...item,
        target_url: item.target_page == 1 ? item.target_url : `${urlList[item.function_id]}menuId=${item.menu_id}&name=${item.nav_name}`
      })); // 获取过滤后的最终的nav列表

      this.navList = !this.userInfo ? data.filter(item => item.auth_type !== 3) : data; // 详情页是否需要登录

      const authInfo = data.find(item => item.function_id == this.menuCode && item.menu_id == this.$utils.getUrlParams('menuId'));

      if (authInfo && authInfo.auth_type == 2 && !this.userInfo) {
        // 修改登录逻辑
        this.handleLogin(true);
      } // 当前页面 导航高亮


      let currentPageIndex = 0;
      if (this.$utils.getUrlParams('menuId')) currentPageIndex = (this.navList || []).findIndex(item => item.menu_id == this.$utils.getUrlParams('menuId'));

      if (this.navList[currentPageIndex]) {
        this.currentPage = this.navList[currentPageIndex].nav_name;
        this.$emit('update:menuInfo', this.navList[currentPageIndex]);
      } // 头部指挥中心等按钮展示规则


      if (this.linkNav.length) {
        const arr = this.linkNav.map(item => item.function_id);
        this.headList = this.centerList.filter(item => !arr.includes(item.code) && item.code !== '16001');
        this.onlineTeache = this.centerList.find(item => item.code == '16001');
      }
    },

    // 基础配置信息
    async getBaseSet() {
      const res = getStorage('projectBaseData') || {};
      this.logoUrl = res.home_logo_url || require('../../assets/images/home_logo.png');
      this.headerBg = res.home_head_bg_url || '';
    },

    // 访问量
    async addPv() {
      let params = {
        clickEnum: 'PAGE',
        loginUserId: this.userInfo ? this.userInfo.id : undefined
      };
      const res = await this.$http.saveBrowseClick(params);
    },

    // 项目状态
    async getObjectStatus() {
      const res = await this.$http.getObjectStatus();

      if (res.status == false) {
        window.location.href = '/stop.html';
      }
    },

    goAccountSet() {
      window.location.href = `/setting.html`;
    },

    // 返回顶部
    backTop() {
      let timer = setInterval(() => {
        let ispeed = Math.floor(-this.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = this.scrollTop + ispeed;

        if (this.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 20);
    },

    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
    }

  }
};