import { getFetch } from '@/plugins/http'
import { postFetch } from '../../plugins/http'

// const baseHost = '//127.0.0.1'
const baseHost = `${process.env.VUE_APP_HOST}/hike-boot`

export default {
  /**
   * 我的课程-列表
   * @param {String}} course_name
   * @param {String} order_type 排序 1 创建 2 更新时间
   */
  getMyCourseList(params = {}, config) {
    return getFetch(`${baseHost}/common/api/34-page_course/bk_my_course`, params, config)
  },
  /**
   * 我的课程-新增
   */
  addMyCourse(params = {}, config) {
    return postFetch(`${baseHost}/common/api/10-add_course/bk_my_course`, params, config)
  },
  /**
   * 我的课程-删除
   */
  delMyCourse(params = {}, config) {
    return postFetch(`${baseHost}/common/api/90-del_course/bk_my_course`, params, config)
  },
  /**
   * 课程类型-列表
   */
  courseClassifyList(params = {}, config) {
    return getFetch(`${baseHost}/common/api/33-list_classify/bk_my_course`, params, config)
  },
  /**
   * 课程-机构-列表
   */
  courseOrganList(params = {}, config) {
    return getFetch(`${baseHost}/common/api/33-organ_tree/bk_my_course`, params, config)
  },
  /*
   * 获取所有课程
   * @returns
   */
  getAllCourseList(params, config) {
    return getFetch(`${baseHost}/common/api/33-list_course/bk_my_course`, params, config)
  },
  /**
   * 教案-我创建的
   * @param {String}} keyword
   * @param {String} sort_type 排序 1 创建 2 更新时间
   */
  getMyCreateCase(params = {}, config) {
    return getFetch(
      `${baseHost}/common/api/34-my_create_case_list/course_lesson_plan_portal`,
      params,
      config
    )
  },
  /**
   * 教案-我协作的
   * @param {String}} keyword
   * @param {String} sort_type 排序 1 创建 2 更新时间
   */
  getMyCollaborationCase(params = {}, config) {
    return getFetch(
      `${baseHost}/common/api/34-my_collaboration_case_list/course_lesson_plan_portal`,
      params,
      config
    )
  },
  /**
   * 教案新增
   */
  addCase(params = {}, config) {
    return postFetch(`${baseHost}/common/api/10-case_add/course_lesson_plan_portal`, params, config)
  },
  /**
   * 教案-全部教案
   */
  getAllCaseList(params = {}, config) {
    return getFetch(
      `${baseHost}/common/api/34-all_case_list/lesson_plan_center_portal`,
      params,
      config
    )
  },
  /**
   * 学期列表
   */
  getTermList(params = {}, config) {
    return getFetch(`${baseHost}/common/api/33-term_list/lesson_plan_center_portal`, params, config)
  },
  /**
   * 消息通知-列表
   * @param {String}} pageNum
   * @param {String} pageSize
   */
  getMessageList(params = {}, config) {
    return getFetch(`${baseHost}/userMsg/getUserMsgPageList`, params, config)
  },
  /**
   * 消息通知-未读消息
   */
  getUnreadMessageList(params = {}, config) {
    return getFetch(`${baseHost}/userMsg/getUserMsgUnreadCount`, params, config)
  },
  /**
   * 消息通知-消息设为已读
   */
  readMessage(params = {}, config) {
    return postFetch(`${baseHost}/userMsg/setUserMsgHaveRead`, params, config)
  }
}
