import { getStorage } from '@/plugins/store';
export default {
  name: 'Footer',

  data() {
    return {
      links: [],
      footer: ''
    };
  },

  created() {
    this.getBaseSet();
  },

  methods: {
    async getBaseSet() {
      const res = getStorage('projectBaseData') || {}; // const res = await this.$http.getBaseSet()

      this.footer = res.copyright;
    }

  }
};