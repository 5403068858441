import { getFetch, postFetch } from "@/plugins/http";

const baseHost = `${process.env.VUE_APP_HOST}`;
const commonApiBaseUrl = `${baseHost}/hike-boot/common/api`;
const zhsTeachingHost = '//fteaching.zhihuishu.com'
const zhsLibraryHost = '//hike-shared-library.zhihuishu.com'
export default {
  /**
  * 教案编辑 
  */
  editCase(params, config) {
    return postFetch(`${commonApiBaseUrl}/10-case_upd/course_lesson_plan_portal`, params, config);
  },
  /**
   * 教案复制
   */
  copyCase(params) {
    return postFetch(`${commonApiBaseUrl}/10-case_copy/course_lesson_plan_portal`, params);
  },
  /**
   * 教案复制-权限判断
   */
  getCopyCaseAuth(params) {
    return getFetch(`${commonApiBaseUrl}/32-case_is_can_copy/course_lesson_plan_portal`, params);
  },
  /**
   * 教案删除
   */
  delCase(params, config) {
    return postFetch(`${commonApiBaseUrl}/90-case_del/course_lesson_plan_portal`, params, config);
  },
  /**
   * 教案权限修改
   */
  updateCaseAuth(params) {
    return postFetch(`${commonApiBaseUrl}/20-case_permission_upd/course_lesson_plan_portal`, params);
  },
  //案例-协作者列表
  getAuthList(data) {
    return getFetch(`${commonApiBaseUrl}/34-collaborator_list/general_website`, data);
  },
  //案例-协作者申请
  isApplyJoin(params) {
    return getFetch(`${commonApiBaseUrl}/32-collaborator_is_apply/general_website`, params);
  },
  //案例-添加协作者
  addCollaboration(params) {
    return postFetch(`${commonApiBaseUrl}/10-collaborator_add/general_website`, params);
  },
  //案例-提交协作
  submitCollaboration(site_id) {
    return postFetch(`${commonApiBaseUrl}/10-site_submit/general_website`, { site_id });
  },
  //案例-添加协作者-待选列表-选择用户
  addCollaborationByChoice(params) {
    return getFetch(`${commonApiBaseUrl}/34-collaborator_wait_list/general_website`, params);
  },
  //获取案例用户角色
  getCaseUserRole(site_id) {
    return getFetch(`${commonApiBaseUrl}/32-team_role/general_website`, { site_id });
  },
  // 案例-基本信息
  getCaseSimInfo(data) {
    return getFetch(`${commonApiBaseUrl}/32-site_info/general_website`, data);
  },
  // 案例-菜单-列表
  getCaseMenu(data) {
    return getFetch(`${commonApiBaseUrl}/33-column_list/general_website`, data);
  },
  // 案例-菜单-删除之前判断是否有内容
  beforwDelMenu(data) {
    return getFetch(`${commonApiBaseUrl}/32-column_del_before/general_website`, data);
  },
  // 案例-菜单-删除
  delCaseMenu(data) {
    return postFetch(`${commonApiBaseUrl}/91-column_del/general_website`, data);
  },
  // 案例-菜单-排序
  sortCaseMenu(data) {
    return postFetch(`${commonApiBaseUrl}/20-column_sort/general_website`, data);
  },
  // 案例-菜单-修改
  updateCaseMenu(data) {
    return postFetch(`${commonApiBaseUrl}/20-column_upd/general_website`, data);
  },
  // 案例-菜单内容-修改
  updateCaseMenuContent(data) {
    return postFetch(`${commonApiBaseUrl}/20-column_content_upd/general_website`, data);
  },
  // 案例-菜单-新增
  addCaseMenu(data) {
    return postFetch(`${commonApiBaseUrl}/10-column_add/general_website`, data);
  },
  // 案例-菜单-子模块
  getCaseMenuChild(data) {
    return getFetch(`${commonApiBaseUrl}/33-column_info/general_website`, data);
  },
  /**
   * 获取学校资源库列表、团队资源
   * @returns 
   */
  getQuoteTeachingResource(data) {
    return getFetch(`${baseHost}/hike-boot/columnFile/getQuoteTeachingResourcePageList`, data);
  },
  /**
   * 获取模块教学资源
   * @returns 
   */
  getColumnResource(data) {
    return getFetch(`${baseHost}/hike-boot/columnFile/getTeachingResourceList`, data);
  },
  /**
   * 保存模块资源文件
   * @returns 
   */
  saveColumnResource(data) {
    return postFetch(`${baseHost}/hike-boot/columnFile/saveColumnFile`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  /**
   * 保存智慧树文件
   */
  saveZHSResource(data) {
    return postFetch(`${baseHost}/hike-boot/columnFile/saveZhsFile`, data);
  },
  /**
   * 校验资源是否允许预览
   */
  resourceIsPreview(data) {
    return getFetch(`${baseHost}/hike-boot/columnFile/cloudFileJudgeAndViewNum`, data);
  },
  /**
        * 案例留言-未读留言数
        */
  unReadMessageCount(params, config) {
    return getFetch(`${commonApiBaseUrl}/32-message_unread_num/general_website`, params, config)
  },
  //案例批注-批注列表（创建者）
  getAnnotationListByCreater(data) {
    return getFetch(`${commonApiBaseUrl}/34-annotation_list/general_website`, data);
  },
  //案例批注-已提交批注列表（协作者）
  getAnnotationList(data) {
    return getFetch(`${commonApiBaseUrl}/34-annotation_submit_list/general_website`, data);
  },
  //案例-保存批注
  saveAnnotation(data) {
    return postFetch(`${commonApiBaseUrl}/10-annotation_save/general_website`, data);
  },
  //案例批注-未读批注数
  getUnreadAnnotation(data) {
    return getFetch(`${commonApiBaseUrl}/32-annotation_unread_num/general_website`, data);
  },
  //案例-未提交批注列表
  getNoSubmitAnnotationList(data) {
    return getFetch(`${commonApiBaseUrl}/33-annotation_no_submit_list/general_website`, data);
  },
  //案例批注-批注查看并标记已读（创建者）
  lookAnnotation(data) {
    return getFetch(`${commonApiBaseUrl}/32-annotation_look/general_website`, data);
  },
  //案例批注-批注提交
  annotationSubmit(data) {
    return postFetch(`${commonApiBaseUrl}/20-annotation_submit/general_website`, data);
  },
  //案例批注-批注回复
  replyAnnotation(data) {
    return postFetch(`${commonApiBaseUrl}/10-annotation_reply/general_website`, data);
  },
  //案例批注-批注采纳
  adoptAnnotation(data) {
    return postFetch(`${commonApiBaseUrl}/20-annotation_adopt/general_website`, data);
  },
  //案例批注-批注删除
  delAnnotation(data) {
    return postFetch(`${commonApiBaseUrl}/90-annotation_del/general_website`, data);
  },
  //案例批注-批注回复删除
  delReply(data) {
    return postFetch(`${commonApiBaseUrl}/10-annotation_reply_del/general_website`, data);
  },
  //修订记录列表
  getReviseList(data) {
    return getFetch(`${commonApiBaseUrl}/33-revise_list/general_website`, data);
  },
  //查询单条修订记录
  getRevise(data) {
    return getFetch(`${commonApiBaseUrl}/30-revise_get/general_website`, data);
  },
  //还原修订记录
  resetRevise(data) {
    return postFetch(`${commonApiBaseUrl}/10-revise_reset/general_website`, data);
  },
  /**
        * 案例留言-留言发送
        */
  sendMessage(params, config) {
    return postFetch(`${commonApiBaseUrl}/10-message_send/general_website`, params, config)
  },
  /**
        * 案例留言-留言列表
        */
  getCaseMessageList(params = {}) {
    return getFetch(`${commonApiBaseUrl}/34-message_list/general_website`, params);
  },
  /**
  * 案例-列表
  */
  getCaseList(params = {}) {
    return getFetch(`${commonApiBaseUrl}/34-excellent_case_list/lesson_plan_center_portal`, params);
  },
  /**
   * 获取学校资源文件信息
   */
  getSchoolResourceInfo(params) {
    return getFetch(`${zhsLibraryHost}/schoolResourceManage/viewResource`, params);
  },
  /**
   * 获取团队资源文件信息
   */
  getTeamResourceInfo(params) {
    return postFetch(`${zhsTeachingHost}/shareCloudFile/getFileUseInfo`, params);
  },
  /**
   * 通过课程id获取课程团队id
   */
  getTeamIdByCourse(params) {
    return getFetch(`${commonApiBaseUrl}/32-getGroupIdByCourseId/bk_course`, params);
  }
}