export const setPagesTitle = school_name => {
  /* 获取初始 title */
  const initialTitle = document.title
  /* 动态设置 title */
  document.title = `${school_name}-${initialTitle}`
}

export const setPagesIcon = icon_url => {
  // 浏览器图标
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = icon_url
  document.getElementsByTagName('head')[0].appendChild(link)
}