import { getFetch, postFetch } from '@/plugins/http'
const baseHost = `${process.env.VUE_APP_HOST}/hike-boot`;

export default {
    // 教材列表
    getMaterialListApi: (params, config) => {
        return getFetch(
            `${baseHost}/common/api/34-getTextbookPageList/portal_homepage`,
            params,
            config
        )
    },
    // 高级设置配置
    getMaterialSettingApi: (params, config) => {
        return getFetch(
            `${baseHost}/common/api/33-getHighSetting/menu_high_setting`,
            params,
            config
        )
    },
    // 机构列表
    getOrganListApi: (params, config) => {
        return getFetch(
            `${baseHost}/common/api/32-getOrganTextbookTree/portal_homepage`,
            params,
            config
        )
    },
    // 教材详情
    getMaterialDetailApi: (params, config) => {
        return getFetch(
            `${baseHost}/common/api/30-TextbookInfo/portal_homepage`,
            params,
            config
        )
    },
    // 教学教材_专业列表
    getMajorListApi: (params, config) => {
        return getFetch(
            `${baseHost}/common/api/33-getMajorTextbookList/portal_homepage`,
            params,
            config
        )
    },
    // 教学教材_获奖列表
    getAwardListApi: (params, config) => {
        return getFetch(
            `${baseHost}/common/api/33-getAwardsTextbookList/portal_homepage`,
            params,
            config
        )
    },
}
