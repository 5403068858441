import { getFetch, postFetch } from '@/plugins/http'

const baseHost = `${process.env.VUE_APP_HOST}/hike-boot`;

export default {
  /* 
    头部信息
  */
  /* 角色权限 */
  findbycourseTeam: (params, config) =>
    getFetch(`${baseHost}/common/api/30-findbycourseid_team/bk_my_course`, params, config),
  /* 课程概况信息查询 */
  courseInfo: (params, config) =>
    getFetch(`${baseHost}/common/api/30-info_course/bk_my_course`, params, config),
  /* 转让负责人列表 */
  transferLeaderList: (params, config) =>
    getFetch(`${baseHost}/common/api/34-transfer_leader_list/bk_my_course`, params, config),
  /* 更新封面 */
  updCourseCover: data =>
    postFetch(`${baseHost}/common/api/20-upd_course_cover/bk_my_course`, data),
  /* 更新基本信息 */
  updCourseBaseInfo: data =>
    postFetch(`${baseHost}/common/api/20-upd_course_name/bk_my_course`, data),
  /* 设置团队角色 */
  updTeamRole: data => postFetch(`${baseHost}/common/api/20-team_role_upd/bk_my_course`, data),
  /* 转让负责人 */
  transferLeader: data => postFetch(`${baseHost}/common/api/20-transfer_leader/bk_my_course`, data),
  /* 
  课程教案
  */
  getFirstOutline: (params, config) =>
    getFetch(`${baseHost}/common/api/32-first_outline/bk_my_course`, params, config),
  /* 课程教案侧边大纲查询 */
  getTreeOutlineinNUm: (params, config) =>
    getFetch(`${baseHost}/common/api/33-tree_outline_V2/bk_my_course`, params, config),
  /* 教案列表 */
  getTeachingPlan: (params, config) =>
    getFetch(`${baseHost}/common/api/32-outline_plans/bk_my_course`, params, config),
  /**
   * 课程管理_教案管理_分配章节
   */
  addOutlineToTeaching: data =>
    postFetch(`${baseHost}/common/api/20-plan_upd_outline/bk_my_course`, data),
  /*
   大纲管理
   */
  /* 大纲列表 */
  getCourseChapterTree: (params, config) =>
    getFetch(`${baseHost}/common/api/33-tree_outline/bk_my_course`, params, config),
  /* 添加大纲 */
  addOutline: data => postFetch(`${baseHost}/common/api/10-add_outline/bk_my_course`, data),
  /* 删除大纲 */
  delOutline: data => postFetch(`${baseHost}/common/api/91-del_outline/bk_my_course`, data),
  /* 更新大纲 */
  updOutline: data => postFetch(`${baseHost}/common/api/20-upd_outline/bk_my_course`, data),
  /* 排序大纲 */
  sortOutline: data => postFetch(`${baseHost}/common/api/20-sort_outline/bk_my_course`, data),
  /* 上传大纲 */
  uploadOutline: (data, config) => postFetch(`${baseHost}/bk/outline/importOutline2`, data, config),
  /* 拖动大纲 */
  dragOutline: (data, config) =>
    postFetch(`${baseHost}/common/api/20-sort2_outline/bk_my_course`, data, config),
  /* 
  课程概况
  */
  /* 课程概况信息查询 */
  surveyCourse: (params, config) =>
    getFetch(`${baseHost}/common/api/30-survey_course/bk_my_course`, params, config),
  /* 课程类型列表 */
  getBkClassifyList: (params, config) =>
    getFetch(`${baseHost}/common/api/33-list_classify/bk_my_course`, params, config),
  /* 教材列表 */
  getMaterialList: (params, config) =>
    getFetch(`${baseHost}/common/api/34-list_material/bk_my_course`, params, config),
  /* 删除教材 */
  delMaterial: data => postFetch(`${baseHost}/common/api/91-del_material/bk_my_course`, data),
  /* 添加教材 */
  addMaterial: data => postFetch(`${baseHost}/common/api/10-add_material/bk_my_course`, data),
  /* 删除课程教材 */
  delCourseMaterial: data =>
    postFetch(`${baseHost}/common/api/90-del_course_material/bk_my_course`, data),
  /* 添加课程教材 */
  addCourseMaterial: data =>
    postFetch(`${baseHost}/common/api/10-add_course_material/bk_my_course`, data),
  /* 更新课程概况信息 */
  updSurveyCourse: data => postFetch(`${baseHost}/common/api/20-upd_course/bk_my_course`, data),
  /* 更新课程背景信息 */
  updDescription: data => postFetch(`${baseHost}/common/api/20-upd_description/bk_my_course`, data),
  /* 
  团队成员
  */
  /* 添加教师 教师列表 */
  teacherList: (params, config) =>
    getFetch(`${baseHost}/common/api/34-list_teacher/bk_my_course`, params, config),
  /* 获取团队成员列表 */
  getTeamDetail: (params, config) =>
    getFetch(`${baseHost}/common/api/30-findbyid_team/bk_my_course`, params, config),
  /* 获取团队详情 */
  getTeamList: (params, config) =>
    getFetch(`${baseHost}/common/api/33-list_team/bk_my_course`, params, config),
  /* 新增教师 */
  addTeacher: data => postFetch(`${baseHost}/common/api/10-add_team/bk_my_course`, data),
  /* 更新教师 */
  updTeacher: data => postFetch(`${baseHost}/common/api/20-upd_team/bk_my_course`, data),
  /* 删除教师 */
  delTeacher: data => postFetch(`${baseHost}/common/api/91-del_teacher/bk_my_course`, data),
  /* 排序教师 */
  sortTeacher: data => postFetch(`${baseHost}/common/api/20-sort/bk_my_course`, data)
}
