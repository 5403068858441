import dayjs from 'dayjs'
import Cookies from 'js-cookie'
import { VIDEOTYPES, AUDIOTYPES, PICTYPES } from './file-types'
import axios from 'axios'
import { getStorage } from './store'
import $http from '@/pages/api/index'
// 获取缩略图
export function getSmallImageUrl(picUrl, picSize) {
  if (picUrl) {
    //如果已经添加s1||s2||s3则不处理
    if (picUrl.indexOf('_s1') != -1 || picUrl.indexOf('_s2') != -1 || picUrl.indexOf('_s3') != -1) {
      return false
    }
    //过滤http
    if (picUrl.indexOf('http:') != -1) {
      picUrl = picUrl.split('http:')[1]
    }
    var x = picUrl.lastIndexOf('.')
    if (x != -1) {
      var prefix = picUrl.substring(0, x)
      var subfix = picUrl.substring(x + 1, picUrl.length)

      if (picSize == 's1') {
        picUrl = prefix + '_s1.' + subfix
      } else if (picSize == 's3') {
        picUrl = prefix + '_s3.' + subfix
      } else {
        picUrl = prefix + '_s2.' + subfix
      }
    }
  } else {
    picUrl =
      '//image.zhihuishu.com/zhs_yufa_150820/ablecommons/demo/201809/50ccbc9664df4b2db5fd62153a24f110.png'
  }
  return picUrl
}

// 获取cookie
// export function getCookie(name) {
//     let strcookie = document.cookie;//获取cookie字符串
//     let arrcookie = strcookie.split("; ");//分割
//     //遍历匹配
//     for (var i = 0; i < arrcookie.length; i++) {
//         var arr = arrcookie[i].split("=");
//         if (arr[0] == name) {
//             return arr[1];
//         }
//     }
//     return "";
// }

export function setCookie(name, value, days = 365) {
  Cookies.set(name, value, { expires: days })
}

// 获取cookie
export function getCookie(name) {
  return Cookies.get(name)
}
//删除cookies
export function delCookie(name) {
  Cookies.remove(name)
}

/**
 * 格式化时间
 */

export const formatDate = (time, formatString = 'YYYY-MM-DD') => {
  if (!time) return ''
  return dayjs(time).format(formatString)
}

/**
 *获取url参数
 * @param {String} name 要查询参数的键名，非必传
 * @returns {Any} 传入name返回value，否则返回全部
 */
export function getUrlParams(name = '') {
  let params = {}
  try {
    let search = location.search.substr(1)
    if (search) {
      search.split('&').forEach(str => {
        const [key, value] = str.split('=')
        params[key] = decodeURIComponent(value)
      })
    }
  } catch (error) {}
  return name ? params[name] : params
}
/**
 * 删除URL中指定search参数,会将参数值一起删除
 * @param {array} aParam 要删除的参数key数组，如['name','age']
 * @return {string} 返回新URL字符串
 */
export function removeUrlParam(aParam = []) {
  let url = window.location.href
  aParam.forEach(item => {
    const fromindex = url.indexOf(`${item}=`) //必须加=号，避免参数值中包含item字符串
    if (fromindex !== -1) {
      // 通过url特殊符号，计算出=号后面的的字符数，用于生成replace正则
      const startIndex = url.indexOf('=', fromindex)
      const endIndex = url.indexOf('&', fromindex)
      const hashIndex = url.indexOf('#', fromindex)

      let reg
      if (endIndex !== -1) {
        // 后面还有search参数的情况
        const num = endIndex - startIndex
        reg = new RegExp(`${item}=.{${num}}`)
        url = url.replace(reg, '')
      } else if (hashIndex !== -1) {
        // 有hash参数的情况
        const num = hashIndex - startIndex - 1
        reg = new RegExp(`&?${item}=.{${num}}`)
        url = url.replace(reg, '')
      } else {
        // search参数在最后或只有一个参数的情况
        reg = new RegExp(`&?${item}=.+`)
        url = url.replace(reg, '')
      }
    }
  })
  const noSearchParam = url.indexOf('=')
  if (noSearchParam === -1) {
    url = url.replace(/\?/, '') // 如果已经没有参数，删除？号
  }
  return url
}
// 过滤html标签，获取纯文本
export function getContentText(content) {
  if (!content) return ''
  let output = content
    .replace(/(\n)/g, '')
    .replace(/(\t)/g, '')
    .replace(/(\r)/g, '')
    .replace(/<\/?[^>]*>/g, '')
    .replace(/\s*/g, '')
    .replace(/&nbsp;/g, ' ')
  let temp = document.createElement('div')

  temp.innerHTML = output
  output = temp.innerText || temp.textContent
  temp = null
  return output
}

// 文件大小转换
export function converByte(bytes) {
  if (!bytes || !Number(bytes)) return '0B'
  var k = 1024,
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  var num = bytes / Math.pow(k, i)
  if (num > 1000) {
    return (num / 1024).toPrecision(2) + sizes[i + 1]
  } else {
    return num.toPrecision(3) + sizes[i]
  }
}
// 文件类型判断
/**
 * @description: 通过后缀获取图标名
 * @param {*} suffix 后缀
 * @return {*} 处理后的iconName
 */
export function formatIcons(suffix) {
  suffix = suffix && suffix.toLowerCase()
  let iconName = 'icon-other'
  if (suffix == 'doc' || suffix == 'docx') {
    iconName = 'icon-word'
  } else if (suffix == 'xls' || suffix == 'xlsx') {
    iconName = 'icon-excel'
  } else if (suffix == 'ppt' || suffix == 'pptx') {
    iconName = 'icon-ppt'
  } else if (suffix == 'txt') {
    iconName = 'icon-txt'
  } else if (suffix == 'pdf') {
    iconName = 'icon-pdf'
  } else if (suffix == 'rar' || suffix == 'zip') {
    iconName = 'icon-zip'
  } else if (PICTYPES.includes(suffix)) {
    iconName = 'icon-image'
  } else if (AUDIOTYPES.includes(suffix)) {
    iconName = 'icon-music'
  } else if (VIDEOTYPES.includes(suffix)) {
    iconName = 'icon-video'
  } else if (suffix === null) {
    iconName = 'icon-link'
  }
  return iconName
}

export function toThumbnail(url, w = 216, h = 123, type = 'm_fill') {
  if (typeof url !== 'string') return url

  let newUrl
  /* 不同域名 后缀处理不同 */
  if (
    url.indexOf('cus-kcsz1.oss-cn-hangzhou.aliyuncs.com') !== -1 ||
    url.indexOf('cus-prod-kcsz1.oss-cn-hangzhou.aliyuncs.com') !== -1 ||
    url.indexOf('image.zhihuishu.com') !== -1
  ) {
    if (url.indexOf('image.zhihuishu.com') !== -1) {
      const lastIndex = url.lastIndexOf('.')
      const frontStr = url.slice(0, lastIndex)
      const lastStr = url.slice(lastIndex)
      if (frontStr.slice(-3).indexOf('_s') === -1) {
        url = frontStr + '_s1' + lastStr
      }
    }

    newUrl = url + `?x-oss-process=image/resize,${type},h_${h}${type === 'm_lfit' ? '' : `,w_${w}`}`
  } else {
    newUrl = url
  }
  return newUrl
}

export function getService() {
  try {
    let url = location.search.substr(9)
    console.log(url)
    return url
  } catch (error) {}
}

// 匹配字符串中的${}
export function replaceString(string, val, newVal) {
  if (string.length) {
    return string.replace(/\$\{.*\}/g, function (word) {
      if (word.indexOf(val) > -1) {
        return newVal
      } else {
        return word
      }
    })
  }
}

/**
 * 时长格式化
 * 时:分：秒
 */

export function dateTransfer(t) {
  let e = Math.round(t),
    i = Math.round(e % 60),
    n = parseInt(e / 60) % 60,
    s = parseInt(e / 60 / 60)
  return (10 <= s ? s : '0' + s) + ':' + (10 <= n ? n : '0' + n) + ':' + (10 <= i ? i : '0' + i)
}

/**
 * 获取登录url
 */
 export function getLoginUrl() {
  let url = ''
  const res = getStorage('projectBaseData') || {}
  // 智慧树登陆
  if (res.zhs_login == 1) {
    const host = window.location.origin
    const schoolId = res.school_id
    url =
      res.zhs_login_url ||
      `${host}/homepage/hike-boot/AuthSys/getAuthorize?redirectUri=${host}/index.html&failRedirectUri=${host}/index.html&source=${schoolId}`
  }
  if (res.sso_login == 1) {
    url = res.sso_login_url
  }
  return url
}


/**
 * 登陆
 */

export function handleLogin(isReplace = false) {
  const url = getLoginUrl()
  if (isReplace) {
    window.location.replace(url)
  } else {
    window.location.href = url
  }
}

/**
 * 登出
 */

 export async function handleLogout () {
    try {
        const res = getStorage('projectBaseData') || {}
        
        if(res.zhs_login == 1) {
            axios.defaults.withCredentials = true
            axios.post('//oauth.zhihuishu.com/logout')
        }

        // 智慧树登陆和学校单点登录--退出登录
        if(res.zhs_login == 1 || res.sso_login == 1) {
            await zhsLogout()
        } else {
            clearToken()
            window.location.href = `/index.html`
        }
    } catch (e) {
        console.log(e)
    }
}

/**
 * 清除本地token
 */

export function clearToken() {
    let token = getCookie('X-Access-Token')

    // 退出登录清除token
    if (token) {
        delCookie('X-Access-Token')
    }
}

/**
 *  智慧树退出登录
 */
export async function zhsLogout() {
    await $http.logout()
    clearToken()
    window.location.href = `//passport.zhihuishu.com/logout?service=${window.location.origin}`
}

// 判断是否被截断
export const isTruncated = (el, isSingleLine = true) => {
  if (isSingleLine) {
    return el.scrollWidth > el.clientWidth
  } else {
    return el.scrollHeight > el.clientHeight
  }
}