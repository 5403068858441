import { IPageInfo } from "../types"
import { reactive } from 'vue'

export default function usePage(update: (pageInfo: IPageInfo) => void) {
  const pageInfo = reactive<IPageInfo>({
    pageLimit: 10,
    pageIndex: 1,
    rows: 10
  })
  
  const pageIndexChange = (pageIndex: number) => {
    pageInfo.pageIndex = pageIndex
    update(pageInfo)
  }

  return {
    pageInfo,
    pageIndexChange
  }
}
