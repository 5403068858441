const __default__ = {
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  },

  data() {
    return {
      lineColor: this.color
    };
  }

};
import { useCssVars as _useCssVars } from 'vue';

const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "35160467": _ctx.lineColor
  }));
};

const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();

  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;