import { getFetch, postFetch } from '@/plugins/http'

const baseHostUrl = `${process.env.VUE_APP_HOST}`

const commonApiBasrUrl = `${baseHostUrl}/hike-boot/common/api`

export default {
  /**
   * 培训研讨列表
   */
  getLectureList(params) {
    return getFetch(`${baseHostUrl}/hike-boot/common/api/34-34/training_web`, params)
  },
  /**
   * 培训研讨-已结束培训
   */
  getFinishLecture(params) {
    return getFetch(`${baseHostUrl}/hike-boot/page/train/finishPage`, params)
  },
  /**
   * 培训研讨详情
   * @param {*} params
   * @returns
   */
  getLectureDetail(params) {
    return getFetch(`${baseHostUrl}/hike-boot/common/api/30-30/training_web`, params)
  },
  /**
   * 线上培训--统计观看记录
   * @param {*} params
   * @returns
   */
  trainViewerRecord(params) {
    return postFetch(`${baseHostUrl}/hike-boot/common/api/10-saveTrainViewers/training`, params)
  },
  /**
   * 培训研讨线下报名
   * @param {*} params
   * @returns
   */
  trainSignUp(params) {
    return postFetch(`${baseHostUrl}/hike-boot/common/api/10-add_applicants/training`, params)
  },
  /**
   * 培训研讨线下报名表单
   * @param {*} params
   * @returns
   */
  getTrainForm(params) {
    return getFetch(`${baseHostUrl}/hike-boot/common/api/33-form_list/general_dict`, params)
  },
  /**
   * 培训研讨线下报名抽签
   * @param {*} params
   * @returns
   */
  trainSignBallot(params) {
    return getFetch(`${baseHostUrl}/hike-boot/common/api/32-isDraw/training_web`, params)
  }
}
