import { getFetch } from '@/plugins/http'
import { postFetch } from '../../plugins/http'

// const baseHost = '//127.0.0.1'
const baseHost = `${process.env.VUE_APP_HOST}`

export default {
  /**
   * 校验ticket
   * @param {String}} ticket
   * @param {String} service 跳转URL
   */
  validateTicket(params = {}, config) {
    return getFetch(`${baseHost}/hike-boot/sys/validate`, params, config)
  },
  /**
   * 通过token获取用户信息
   */
  getUserInfo(params = {}) {
    return getFetch(`${baseHost}/hike-boot/sys/user/info`, params)
  },
  /**
   * 登录
   */
  getLogin(params = {}, config) {
    return postFetch(`${baseHost}/hike-boot/sys/login`, params, config)
  },
  /**
   * 发送短信验证码
   */
  getMessage(params = {}, config) {
    return postFetch(`${baseHost}/hike-boot/sendSms/sendSmsCode`, params, config)
  },
  /**
   * 验证短信验证码
   */
  validateCode(params = {}, config) {
    return postFetch(`${baseHost}/hike-boot/sendSms/validateCode`, params, config)
  },
  /**
   * 验证密码
   */
  validatePassword(params = {}, config) {
    return getFetch(`${baseHost}/hike-boot/user/certificationPassword`, params, config)
  },
  /**
   * 确认重置, 通过手机号修改密码
   */
  resetPass(params = {}, config) {
    return getFetch(`${baseHost}/hike-boot/user/revisePasswordByPhone`, params, config)
  },
  /**
   * 退出登录
   * @param {*} params
   * @returns
   */
  logout() {
    return postFetch(`${baseHost}/hike-boot/sys/logout`, {})
  },
  // 通过旧密码修改密码
  changePassword(params) {
    return getFetch(`${baseHost}/hike-boot/user/revisePasswordByFomrerPassword`, params)
  },
  /**
   * Logo_背景
   */
  // getLogoBack(params) {
  //   return getFetch(`${baseHost}/hike-boot/common/api/32/page_homepage`, params)
  // },
  /**
   * 查询权限
   */
  getConfigList(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-query_auth/common_config`, params)
  },
  /**
   * 导航
   */
  getNavList(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-nav_list/portal_homepage`, params)
  },
  /**
   * 教学数据
   */
  getTeachData(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-data_display_list/portal_homepage`, params)
  },
  /**
   * 数据列表
   */
  getDataList(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-all_list/portal_homepage`, params)
  },
  /**
   * 首页_Banner
   */
  getBannerList(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-banner_list/portal_homepage`, params)
  },
  /**
   * 首页_今日直播
   */
  getHomeLive(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-today_live/portal_homepage`, params)
  },
  /**
   * 首页_培训研讨
   */
  getHomeTranings(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/34-34/training_web`, params)
  },
  /**
   * 首页_友情链接
   */
  getFooterLink(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-links_list/portal_homepage`, params)
  },
  /**
   * 首页_精品MOOC
   */
  getHomeMooc(params) {
    return getFetch(
      `${baseHost}/hike-boot/common/api/33-recommend_mooc_list/portal_homepage`,
      params
    )
  },
  /**
   * 首页_spoc和mooc类型
   */
  getCourseType(params) {
    return getFetch(
      `${baseHost}/hike-boot/common/api/33-rcm_spoc_classify_list/portal_homepage`,
      params
    )
  },
  /**
   * 首页_精品SPOC
   */
  getHomeSpoc(params) {
    return getFetch(
      `${baseHost}/hike-boot/common/api/33-recommend_spoc_list/portal_homepage`,
      params
    )
  },
  /**
   * 首页_新闻公告
   */
  getHomeNews(params) {
    return getFetch(
      `${baseHost}/hike-boot/common/api/33-recommend_news_list/portal_homepage`,
      params
    )
  },
  /**
   * 首页_新闻公告-类型
   */
  getNewsClassify(params) {
    return getFetch(
      `${baseHost}/hike-boot/common/api/33-rcm_news_classify_list/portal_homepage`,
      params
    )
  },
  /**
   * 首页_推荐名师
   */
  getHomeTeachers(params) {
    return getFetch(
      `${baseHost}/hike-boot/common/api/33-recommend_teacher_list/portal_homepage`,
      params
    )
  },
  /**
   * 课程列表
   */
  getCourseList(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/34-page_course/portal_course`, params)
  },
  /**
   * 课程-查询MOOC高级设置
   */
  getMoocSet(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/32-course_setting/portal_course`, params)
  },
  /**
   * 课程-查询SPOC高级设置
   */
  getSpocSet(params) {
    // return getFetch(`${baseHost}/hike-boot/common/api/33/portal_course`, params)
    return getFetch(`${baseHost}/hike-boot/common/api/33-spoc_setting/portal_course`, params)
  },
  /**
   * 课程-筛选列表
   */
  getFilterList(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-tree/portal_course`, params)
  },
  /**
   * 名师-荣誉称号
   */
  getTeacherTitle(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-33/portal_teacher`, params)
  },
  /**
   * 名师-学院
   */
  getTeacherCollege(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-331/portal_teacher`, params)
  },
  /**
   * 名师-机构列表树
   */
  getOrganTree(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-organ_tree/manage_base`, params)
  },
  /**
   * 名师-列表分页查询
   */
  getTeacherList(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/34-34/portal_teacher`, params)
  },
  /**
   * 名师-详情 ID
   */
  getTeacherDetail(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/30-30/portal_teacher`, params)
  },
  /**
   * 名师-推荐课程
   */
  getTeacherCourse(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-332/portal_teacher`, params)
  },
  /**
   * 名师-学期
   */
  getTermList(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-333/portal_teacher`, params)
  },
  /**
   * 名师-高级设置
   */
  getTeacherSet(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-334/portal_teacher`, params)
  },
  /**
   * 资讯-列表
   */
  getNewsList(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/34-34/portal_news`, params)
  },
  /**
   * 资讯-分类查询
   */
  getNewsClass(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/34-341/portal_news`, params)
  },
  /**
   * 资讯-详情
   */
  getNewsDetail(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/30-30/portal_news`, params)
  },
  /**
   * 增加观看次数
   */
  addViewCount(params, config) {
    return postFetch(`${baseHost}/hike-boot/common/api/20-20/portal_news`, params, config)
  },
  /**
   * 资讯-附件
   */
  getNewsFiles(params, config) {
    return getFetch(`${baseHost}/hike-boot/common/api/34-342/portal_news`, params, config)
  },
  /**
   * 基础配置信息
   */
  getBaseSet(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/32-base_info/common_config`, params)
  },
  /**
   * 门户访问量
   */
  addPv(params, config) {
    return postFetch(`${baseHost}/hike-boot/common/api/10-pv_add/portal_view`, params, config)
  },
  /**
   * 浏览量统计
   */
  saveBrowseClick(params) {
    return postFetch(`${baseHost}/hike-boot/browse/saveBrowseClick`, params)
  },
  /**
   * 用户详情
   */
  getUserDetail(params, config) {
    return getFetch(`${baseHost}/hike-boot/common/api/32-getUserInfo/manage_user`, params, config)
  },
  /**
   * 用户最后登录时间
   */
  getLastLogin(params, config) {
    return getFetch(
      `${baseHost}/hike-boot/common/api/32-getUserLoginTime/manage_user`,
      params,
      config
    )
  },
  /**
   * 账号设置-修改
   */
  changeInfo(params, config) {
    return postFetch(
      `${baseHost}/hike-boot/common/api/20-updateUserByLoginId/manage_user`,
      params,
      config
    )
  },
  /**
   * 职称列表
   */
  getTitleList(params, config) {
    return getFetch(`${baseHost}/hike-boot/common/api/34-title_list/manage_base`, params, config)
  },
  /**
   * 验证用户信息
   */
  confirmCode(params, config) {
    return getFetch(`${baseHost}/hike-boot/sendSms/validateCode`, params, config)
  },
  /**
   * 直播-liebiao
   */
  getLiveList(params, config) {
    return getFetch(`${baseHost}/hike-boot/common/api/34-34/portal_live`, params, config)
  },
  /**
   * 直播-详情
   */
  getLiveDetail(params, config) {
    return getFetch(`${baseHost}/hike-boot/common/api/30-30/portal_live`, params, config)
  },
  // 项目已开启功能
  getObjectOpen(params, config) {
    return getFetch(
      `${baseHost}/hike-boot/common/api/33-enabled_function/common_config`,
      params,
      config
    )
  },
  // 项目状态
  getObjectStatus(params, config) {
    return getFetch(
      `${baseHost}/hike-boot/common/api/32-project_status/common_config`,
      params,
      config
    )
  },
  //获取阿里云上传签名
  getPolicy(params) {
    return postFetch(`${baseHost}/hike-boot/file/getPolicy`, params)
  },
  /**
   *获取智慧树用户身份
   * @param {Number} uuid schoolId
   */
  getUserIdentity(params = {}) {
    return getFetch('//fteaching.zhihuishu.com/login/getUserIdentity', params)
  },
  /**
   *获取智慧树权限
   * @param {Number} uuid
   */
  getUserZhsIdentity(params = {}) {
    return getFetch('//fteaching.zhihuishu.com/login/aidMenuAuth', params)
  },
  /**
   * 智慧树解除绑定
   */
  unbindZhsAccount(params) {
    return getFetch(`${baseHost}/hike-boot/AuthSys/unbind`, params)
  },

  /**
   * 获取文件预览信息
   */
  getFileInfo(params) {
    return getFetch(`${baseHost}/hike-boot/file/getFileInfoById`, params)
  },

  /**
   * 首页_应用列表
   */
  getAppList(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-app_list/portal_homepage`, params)
  },

  /**
   * 获取视频转码后的路径
   * @param {*} params
   * @returns
   */
  getTranscodeUrl(params) {
    return getFetch(`${baseHost}/hike-boot/file/getTranscodeUrl`, params)
  },

  /**
   * 获取绑定智慧树类型
   * @param {*} params
   * @returns
   */
  getBindZhsType(params) {
    return getFetch(`${baseHost}/hike-boot/common/api/33-kcsz_case_center/kcsz_case_center`, params)
  },

  /**
   * 绑定智慧树(不能解绑)
   * @param {*} params
   * @returns
   */
  bindZhsForever(params) {
    return getFetch(`${baseHost}/hike-boot/ssoLogin/zhsSsoLogin`, params)
  },
  /**
   * 修改用户认证信息、密码时-获取验证码接口
   * @param {*} params
   * @returns
   */
  getAuthCode(params) {
    return postFetch(`${baseHost}/hike-boot/authSendSms/sendSmsCode`, params)
  },
  /**
   * 修改用户认证信息、密码时-验证验证码接口
   * @param {*} params
   * @returns
   */
  validateAuthCode(params) {
    return postFetch(`${baseHost}/hike-boot/authSendSms/validateCode`, params)
  },
  /**
   * 基础获取验证码
   */
  basicSendSms(data) {
    return postFetch(`${baseHost}/hike-boot/api/basicSendSms/sendSmsCode`, data)
  }
}
